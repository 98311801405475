<template>
  <div class="home-page__container">
    <main-screen/>
    <about-conference/>
    <speakers/>
    <themes/>
    <place/>
    <sponsors/>
    <ticket-variations/>
    <tickets/>
    <main-footer/>
  </div>
</template>

<script>
import MainScreen from './MainScreen'
import AboutConference from './AboutConference'
import Speakers from './Speakers'
import Themes from './Themes'
import Place from './Place'
import MainFooter from './MainFooter'
import Sponsors from './Sponsors'
import Tickets from './Tickets'
import TicketVariations from './TicketVariations'

export default {
  name: 'HomePage',
  components: {
    MainScreen,
    AboutConference,
    Speakers,
    Themes,
    Place,
    TicketVariations,
    Tickets,
    Sponsors,
    MainFooter
  }
}
</script>

<style>
.global__visible-element {
  z-index: 999;
}

.global__standard-text {
  font-size: 20px;
}

.global__round-section {
  border-radius: 50%;
  border: 1px solid white;
}

.global__section-margin {
  margin-top: 100px;
}
</style>
