<template>
  <div class="footer__container v-container">
    <v-row v-if="largeScreen" class="mt-8 mb-10">
      <v-spacer/>
      <v-col class="col-3 col-md-2 col-lg-1 mr-4 mr-md-6 mr-lg-10">
        <share-block></share-block>
      </v-col>
      <v-col class="col-3 col-md-2 d-flex flex-column justify-space-between">
        <a
          class="white--text font-weight-bold text-decoration-underline"
          href="mailto:devfest@gdg.org.ua">
          Email us
        </a>
        <img class="gdg-logo justify-end" :src="require('../assets/images/gdg-logo.svg')" alt="">
      </v-col>
      <v-col class="col-3 col-md-2 col-lg-1 ml-4 ml-md-6">
        <follow-block></follow-block>
      </v-col>
      <v-spacer/>
    </v-row>
    <v-row v-else class="mt-8 mb-10">
      <v-col class="ml-10">
        <follow-block></follow-block>
        <a
          class="d-block mt-6 white--text font-weight-bold text-decoration-underline"
          href="mailto:devfest@gdg.org.ua">
          Email us
        </a>
      </v-col>
      <v-col>
        <share-block></share-block>
        <img class="mt-15 gdg-logo--mobile" :src="require('../assets/images/gdg-logo.svg')" alt="">
      </v-col>
    </v-row>
  </div>
</template>

<script>

import ShareBlock from '@/components/Reusable/ShareBlock'
import FollowBlock from '@/components/Reusable/FollowBlock'

export default {
  name: 'MainFooter',
  components: {
    ShareBlock,
    FollowBlock
  },
  computed: {
    largeScreen () {
      return this.$vuetify.breakpoint.name !== 'xs'
    }
  }
}
</script>

<style scoped>
.gdg-logo {
  width: 140px;
  height: auto;
}

.gdg-logo--mobile {
  width: 120px;
}
</style>
