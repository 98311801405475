<template>
  <div id="scrollToMe" class="tickets__container">
    <h1 class="tickets__title">Tickets</h1>
    <div class="tickets__wrap d-flex mt-10 flex-wrap justify-space-between">
      <div class="ticket__container" v-for="(ticket, index) in tickets" :key="index">
        <v-card
          link
          href="https://lovetechproblems.ticketforevent.com/"
          target="_blank"
          class="ticket__card d-flex flex-column justify-space-between">
          <h3 class="ticket__name">{{ ticket.name }}</h3>
          <h3 class="ticket__price">₴ {{ ticket.price }}</h3>
          <p class="ticket__description">{{ticket.description}}</p>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ticket-variations',
  data() {
    return {
      tickets: [{
        name: 'Student',
        description: '*Valid only with student ID',
        price: 150
      }, {
        name: 'Special price',
        description: '*Only 10 tickets',
        price: 250
      }, {
        name: '2 for 1',
        description: '*Buy 2 regular tickets for 1 price',
        price: 250
      }, {
        name: 'Regular',
        price: 500
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';
@import '../assets/styles/functions';

.tickets__container {
  margin: 80px $ticket-margin-in-vw 0 $ticket-margin-in-vw;
}

.tickets__title {
  font-weight: 500;
  font-size: 32px;
  text-align: center;
}

.ticket__container {
  width: calculateTicketSize($ticket-margin-in-vw, $margin-between-tickets, 4);
  min-height: 240px;
}

.ticket__card {
  padding: 30px 40px;
  height: 100%;
}

.ticket__price {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: $accent-light;
}

.ticket__name {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.ticket__description {
  font-size: 10px;
  padding: 0;
  margin: 0;
  opacity: 0.7;
  text-align: center;
  min-height: 30px;
}

@media (max-width: 960px) {
  .tickets__container {
    margin: 50px 10vw 0 10vw;
  }

  .tickets__wrap {
    justify-content: space-around !important;
  }
  .ticket__container {
    width: 250px;
  }
  .ticket__container:nth-child(n+4),
  .ticket__container:nth-child(n+3) {
    margin-top: 20px;
  }
}

@media (max-width: 650px) {
  .tickets__container {
    margin: 50px 40px 0 40px;
  }

  .ticket__container {
    width: calc((100vw - 50px * 2) / 2);
    min-height: 200px;
  }

  .ticket__container:nth-child(n+4),
  .ticket__container:nth-child(n+3) {
    margin-top: 20px;
  }

  .ticket__price {
    font-size: 20px;
    font-weight: 500;
  }

  .ticket__name {
    font-size: 14px;
    min-height: 42px;
  }

  .tickets__title {
    text-align: left;
  }
}
</style>
