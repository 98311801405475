<template>
  <div class="place__container d-flex flex-column align-center justify-center">
    <p class="text-center mb-8">Place</p>
    <img src="../assets/images/molodo-zeleno.svg" alt="" width="200">
    <p class="text-center mt-8">Dzherelna Street, 20<br/>Lviv</p>
  </div>
</template>

<script>
export default {
  name: 'Place'
}
</script>

<style scoped>
p {
  font-size: 32px;
  font-weight: 500;
}

.place__container {
  height: 640px;

  margin-top: 100px;

  background-image: url(../assets/images/place.svg);
  background-size: cover;
}

.image-place {
  width: 100%;
  height: auto;
}
</style>
