<template>
  <div class="about-conference__container global__section-margin">
    <div class="about-conference-text__container">
      <h1 class="about-conference__title">
        “Love. Tech. Problems”
      </h1>
      <p
        class="about-conference__text global__standard-text">
        Is it a fuck-up night? Oh, no. It’s something better. You will hear about real problems our speakers have faced working in mobile development. Not a problem you can solve reading documentation, but a major fuck-up that made our speakers work all night. Simple talks about unexpected troubles and how to deal with them.
      </p>
      <p class="about-conference__text global__standard-text">
        So, we invite you to spend a great Sunday in MolodoZeleno, hearing talks from cool speakers, drinking cocktails on a terrace, and connecting with awesome people.
      </p>
      <p
        class="about-conference__text global__standard-text">
        Speeches language: <b>Ukrainian</b>.
      </p>
    </div>

    <div class="about-conference__statistic">
      <div class="about-conference__circle-text global__round-section ">
        <span class="figures">4</span>
        <span class="small-text">Speeches</span>
      </div>
      <div class="about-conference__circle-text global__round-section">
        <span class="figures">50+</span>
        <span class="small-text">Attenders</span>
      </div>
      <div class="about-conference__circle-text global__round-section">
        <span class="figures">5</span>
        <span class="small-text">Hours</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutConference'
}
</script>

<style scoped>
.about-conference__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about-conference-text__container {
  margin: 0 20%;
}
.about-conference__title {
  font-weight: 700;
  font-size: 46px;
  line-height: 48px;
  text-align: center;
}
.about-conference__text {
  margin-top: 20px;
}
.about-conference__statistic {
  width: 60%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: center;
}

.about-conference__circle-text {
  display: flex;
  flex-direction: column;
  width: 33%;
  align-items: center;
  min-width: 200px;
}

.figures {
  font-size: 64px;
  font-weight: 700;
}

.small-text {
  font-size: 24px;
  margin-bottom: 20px;
}

.about-conference__text--gray {
  opacity: 0.7;
}

@media (max-width: 640px) {
  .about-conference-text__container {
    margin: 0 40px;
  }

  .about-conference__title {
    font-size: 30px;
    line-height: 32px;
    text-align: left;
  }

  .about-conference__text {
    text-align: left;
  }

  .about-conference__circle-text {
    min-width: 100px;
  }

  .figures {
    font-size: 24px;
    line-height: 24px;
    margin-top: 6px;
  }

  .small-text {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .about-conference__statistic {
    width: 80%;
  }

  .about-conference__container {
    margin-top: 0;
  }

  .global__standard-text {
    font-size: 18px;
    font-weight: 400;
  }
}
</style>
