<template>
  <div class="sponsors__wrapper">
    <h1 class="sponsors__title">Sponsors</h1>
    <div class="mt-10 d-flex justify-center">
      <div
        class="sponsor__container d-flex flex-grow-1 justify-center"
        v-for="(sponsor, index) in sponsors" :key="index">
        <a class="sponsor__image__container" :href="sponsor.link" target="_blank">
          <img
            class="sponsor__image"
            :src="require('../assets/images/sponsors/'+sponsor.photo)"
            :alt="sponsor.name">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'sponsors',
    data () {
      return {
        sponsors: [{
          photo: 'vakoms.svg',
          name: 'Vakoms',
          link: 'https://vakoms.com/'
        },{
          photo: 'wix.png',
          name: 'Wix',
          link: 'https://www.wix.engineering/'
        }, {
          photo: 'sigma.svg',
          name: 'Sigma',
          link: 'https://sigma.software/'
        }]
      }
    }

  }
</script>

<style scoped>
.sponsors__wrapper {
  margin: 80px 10vw;
}

.sponsors__title {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.sponsor__image__container {
  width: 26vw;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 0 20px;
}

.sponsor__container:nth-child(odd) {
  padding: 0 20px !important;
}

.sponsor__image__container img {
  width: 100%;
  height: auto;
}
.sponsor__container {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  width: 26.6vw;
}

.sponsor__container:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.2);;
}

@media (max-width: 650px) {
  .sponsors__wrapper {
    margin: 50px 40px 0 40px;
  }

  .sponsors__title {
    text-align: left;
  }
}
</style>
