import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    theme: {
      themes: {
        dark: {
          primary: '#000000',
          secondary: '#FFFFFF'
        }
      }
    }
  }
});
