<template>
  <div class="themes__container">
    <h1 class="themes__title">Themes</h1>
    <div v-if="largeScreen">
      <div class="themes__list__container d-flex mt-10 flex-wrap align-content-space-between">
        <div class="theme__container" v-for="(theme, index) in themes" :key="index">
          <h3 class="theme__title">{{ theme.title }}</h3>
          <h5 class="theme__speaker">
            <span class="theme__speaker__name">{{ theme.speaker }}</span>
            <span class="theme__speaker__company"> - {{ theme.company }}</span>
          </h5>
          <p class="theme__text" v-html="theme.text"></p>
        </div>
      </div>
    </div>
    <!--For mobile devices-->
    <div v-else class="mt-8">
      <v-divider></v-divider>
      <v-expansion-panels class="themes__expansion">
        <v-expansion-panel v-for="(theme, index) in themes" :key="index">
          <v-expansion-panel-header class="theme__title">
            {{ theme.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <h5 class="theme__speaker">
              <span class="theme__speaker__name">{{ theme.speaker }}</span>
              <span class="theme__speaker__company"> - {{ theme.company }}</span>
            </h5>
            <p class="theme__text" v-html="theme.text"></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Themes',
  data () {
    return {
      themes: [{
        title: 'Retrospective and pain of indie game developers',
        speaker: 'Dan Syrotynskyy & Nick Urs',
        company: `Dragon Games`,
        text: `<span class="d-flex mt-2">In this talk, Dan and Nick will share with You their way to make computer games.</span>
               <span class="d-flex mt-2">You'll hear how young game developers can survive for 1000₴ a year, how to visit Spain's game startup accelerator and live as if it was communism, and how to start a gaming company completely fail it.</span>
               <span class="d-flex mt-2">They have survived and achieved great success in gaming so the story gonna has a happy ending.</span>
               <span class="d-flex mt-2">Speech language: Ukrainian.</span>`
      }, {
        title: 'Терпи, козаче, отаманом будеш!',
        speaker: 'Serj Miskiv',
        company: 'Teacher at APPS UCU',
        text: `<span class="d-flex mt-2">Українські військові активно використовують смартфони в службових і особистих цілях. Це об’єктивна реальність сучасного світу, при цьому, регламентувати їх діяльність в Мережі практично неможливо. Це добре відомо і нашому ворогу. Кремль активно використовує мобільні аплікації як для збору інформації, так і з пропагандистською метою. Як один із факторів протидії цьому і був створений додаток “Джура” про який Серж і розповість.</span>
               <span class="d-flex mt-2">Мова доповіді: Українська.</span>`
      }, {
        title: 'How to make apps better, safer and 10x cheaper',
        speaker: 'Ivan Danyliuk',
        company: 'Sportity',
        text: `<span class="d-flex mt-2">Ivan'll tell you about his journey and approach to writing modern high-quality mobile, desktop and web apps.</span>
               <span class="d-flex mt-2">You’ll hear how COVID pandemics reinforced the principles behind his choices as a CTO (and the only developer for some projects) and led to the creation of a tool that boosts the quality, safety, and costs of full-stack app development by a factor of 10.</span>
               <span class="d-flex mt-2">The talk focuses on the philosophy, challenges, and approaches behind it, so you can bring home some valuable lessons too.</span>
               <span class="d-flex mt-2">Speech language: Ukrainian.</span>`

      }, {
        title: 'Wix 404 bar talks - open-source, cache invalidation and how the 404 error can send you on a business trip',
        speaker: 'Sergiy Mokiyenko and Mykhailo Pustovit',
        company: 'Wix Engineering',
        text: `<span class="d-flex mt-2">Failures come with a lot of stress! No worries - just visit the Wix 404 Bar, get an anti-stress drink, and turn any misstep into a valuable lesson. Here’s our agenda for the day: </span>
                <ul class="d-flex mt-2 flex-column">
                  <li>The devil behind the small changes</li>
                  <li>How the 404 error can send you on a business trip</li>
                  <li>Open Source is eating your code or the story of one pull request</li>
                  <li>Invalidate your cache properly or undefined all around</li>
                </ul>
               <span class="d-flex mt-2">Speech language: Russian.</span>`
      }]
    }
  },
  computed: {
    largeScreen () {
      return this.$vuetify.breakpoint.name !== 'xs'
    }
  }
}
</script>

<style lang="scss" scoped>
 @import '../assets/styles/variables';

 .themes__container{
   margin: 100px $speakers-margin-in-vw 0 $speakers-margin-in-vw;
 }

 .themes__title {
   font-weight: 500;
   font-size: 32px;
   text-align: center;
 }

 .theme__container {
   flex-basis: 45%;
 }

 .theme__title {
   font-size: 20px;
   font-weight: 700;
 }

 .theme__text {
   text-align: justify;
   opacity: 0.7;
 }
 .theme__container:nth-child(even) {
   margin-left: 10%;
 }

 .theme__container:nth-child(4n),
 .theme__container:nth-child(4n+3) {
   margin-top: 40px;
 }


 .themes__paragraph--margin {
   margin-top: 4px;
 }

 .theme__speaker {
   margin-top: 8px;
   font-size: 16px;
   font-weight: 500;
 }

 .theme__speaker__company {
   color: #0E4A1F;
 }

 .theme__speaker__name {
   color: #1B953D;
 }

 @media (max-width: 600px) {
  .themes__container {
    margin: 64px $speakers-margin-in-px  0 $speakers-margin-in-px;
  }

  .themes__title {
    font-size: 28px;
    font-weight: 500;
    text-align: left;
  }

  .theme__title {
    font-size: 20px;
    font-weight: 400;
  }
 }
</style>

<style>
.themes__expansion.v-expansion-panels .v-expansion-panel {
  background-color: #121212 !important;
}

.themes__expansion .v-expansion-panel-header {
  padding: 16px 0 !important;
}

.themes__expansion .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
