<template>
  <div class="header-toolbar__container">
    <img class="header-toolbar__logo global__visible-element" src="../assets/images/gdg-logo.svg" alt="GDG logo" >
    <v-spacer class="header-toolbar__spacer--pc"></v-spacer>
    <span
      class="header-toolbar__title global__visible-element">
        01.10.21 / 18:00
    </span>
    <v-btn
      @click="buyTickets"
      outlined
      dark
      class="header-toolbar__buy-ticket round-button global__visible-element">
      buy ticket
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'HeaderToolbar',
  methods: {
    buyTickets () {
      const el = document.getElementById('scrollToMe');
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>

<style scoped>
.header-toolbar__container {
  height: 20px;
  display: flex;
  margin: 20px 0;
  align-items: center;
}

.header-toolbar__title {
  margin: 0 30px;
}
.header-toolbar__logo {
  margin-right: 20px;
}

.round-button {
  border-radius: 100px;
  border: 1px solid white;
}

@media (max-width: 640px) {
  .header-toolbar__logo {
    height: 16px;
  }

  .header-toolbar__container {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .header-toolbar__title {
    display: none;
  }

  .header-toolbar__buy-ticket {
    width: 80vw;
    background-color: #000000 !important;
    color: #FFF !important;
    margin-bottom: 20vh;
    height: 48px !important;
  }
}
</style>
