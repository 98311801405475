<template>
  <div>
    <img class="ellipse-element-1" src="../assets/images/ellipse-element.svg" alt="ellipse">
    <div class="main-screen__container">
      <header-toolbar/>
    </div>
  </div>
</template>

<script>
import HeaderToolbar from './HeaderToolbar'

export default {
  name: 'MainScreen',
  components: {
    HeaderToolbar
  },
  methods: {
    buyTickets () {
      window.open('https://lovetechproblems.ticketforevent.com/')
    }
  }
}
</script>

<style scoped>
.main-screen__container {
  height: 100vh;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  background-image: url("../assets/images/landing.png");
  background-position: center;
  background-size: contain;
  z-index: 999;
  position: relative;
}

.ellipse-element-1 {
  position: absolute;
  width: 781px;
  height: 781px;
  left: -150px;
  top: 0;
  z-index: 1;
}

.main-screen__motto {
  width: 100%;
  z-index: 999;
  align-self: center;
  height: 100%;
}

@media (max-width: 640px) {
  .main-screen__container {
    background-image: url("../assets/images/landing-mobile.png");
    padding: 0 6%;
    margin: 0 5%;
  }

  .ellipse-element-1 {
    display: none;
  }
}

</style>
