<template>
  <div class="tickets__container d-flex align-center">
    <v-row class="tickets__row d-flex align-center">
      <v-col class="tickets__text__container d-flex flex-column align-center">
        <div class="align-self-start">
          <h4 class="tickets__text__title pl-6">Love.Tech.Problems</h4>
          <ul class="ticket__text__list mt-4">
            <li>1 October 2021</li>
            <li>MolodoZeleno <br> Ukraine, Lviv</li>
          </ul>
        </div>
      </v-col>
      <v-spacer class="tickets__spacer"/>
      <v-col class="tickets__button__container d-flex justify-end">
        <v-btn color="white" class="align-self-center tickets__action__button" @click="buyTickets">
          Buy ticket
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Tickets',
  methods: {
    buyTickets () {
      window.open('https://lovetechproblems.ticketforevent.com/')
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/variables';

.tickets__container {
  width: 100%;
  height: 500px;
  background-image: url("../assets/images/ticket-hand.png");
  background-position: center;
  background-size: contain;
}

.tickets__row {
  width: 100%;
  height: 100%;
  margin: 0 $ticket-margin-in-vw;
}

.tickets__action__button {
  height: 48px !important;
  width: 174px;

  background-color: #ffffff;
  color: black;
  border-radius: 50px;
}

.tickets__text__container {
  font-size: 32px;
  font-weight: 700;
}

.tickets__text__title {
  font-size: 22px;
}

.ticket__text__list {
  font-size: 18px;
  font-weight: 500;
}

.ticket__text__list li {
  margin-top: 10px;
}

/* Mobile */
@media (max-width: 960px) {
  .tickets__container {
    background-size: cover;
  }

  .tickets__row {
    flex-direction: column;
  }

  .tickets__text__container,
  .tickets__spacer {
    display: none !important;
  }

  .tickets__button__container {
    padding-bottom: 37px;
    justify-content: center !important;
  }

  .tickets__action__button {
    width: 90%;
    background-color: #000000 !important;
    color: #ffffff;
    border: 0.5px solid #ffffff;
    margin-top: auto;
  }
}
</style>
