<template>
  <div>
    <span class="font-weight-bold">Share:</span>
    <ShareNetwork
        class="d-block white--text text-decoration-underline mt-2"
        network="facebook"
        url="http://lovetechproblems.gdg.org.ua"
        title="Visit our website">
      Facebook
    </ShareNetwork>
    <ShareNetwork
        class="d-block white--text text-decoration-underline mt-2"
        network="twitter"
        url="http://lovetechproblems.gdg.org.ua"
        title="Visit our website">
      Twitter
    </ShareNetwork>
  </div>
</template>

<script>
export default {
  name: 'ShareBlock'
}
</script>

<style scoped>

</style>