<template>
  <div
    v-if="speakers.length > 0"
    class="speakers__container d-flex flex-column">
    <h1 class="speakers__container__title">Speakers</h1>
    <div class="speakers__list d-flex justify-space-between">
      <div
        class="speakers__list__speaker d-flex flex-column align-center"
        v-for="(speaker, index) in speakers"
        :key="index">
        <div
          v-if="speaker.photos.length === 1"
          class="speakers__list__speaker__image global__round-section d-flex flex-column align-center justify-center">
          <img :src="require('../assets/images/speakers/'+speaker.photos[0])" alt="speaker.name">
        </div>
        <div
          class="speakers__list__speaker__image global__round-section d-flex justify-center"
          v-else>
          <div class="speakers__list__speakers__image speakers__list__speakers__image--right-border d-flex align-center">
            <img :src="require('../assets/images/speakers/'+speaker.photos[0])" alt="speaker.name">
          </div>
          <div class="speakers__list__speakers__image d-flex align-center">
            <img :src="require('../assets/images/speakers/'+speaker.photos[1])" alt="speaker.name">
          </div>
        </div>
        <span v-if="speaker.names.length >= 1" class="speaker__name mt-3">{{ speaker.names[0] }}</span>
        <span v-if="speaker.names.length >= 2" class="speaker__name">& {{ speaker.names[1] }}</span>
        <span class="speaker__company mt-2 text-center">{{ speaker.company }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'speakers',
  data() {
    return {
      speakers: [{
        names: ['Dan Syrotynsky', 'Nick Urs'],
        photos: ['dan_syrotynskyy.jpg', 'nick_urs.png'],
        company: 'Dragon Games'
      }, {
        names: ['Serj Miskiv'],
        photos: ['serhii_myskiv.jpg'],
        company: 'Teacher at APPS UCU'
      }, {
        names: ['Ivan Danyliuk'],
        photos: ['ivan_danyliuk.png'],
        company: 'Sportity'
      }, {
        names: ['Sergiy Mokiyenko', 'Mykhailo Pustovit'],
        photos: ['sergiy_mokienko.jpg', 'mykhailo_pustovit.jpg'],
        company: 'Wix Engineering'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/styles/variables';
  @import '../assets/styles/functions';

.speakers__container {
  margin: 100px $speakers-margin-in-vw 0 $speakers-margin-in-vw;
}

.speakers__list {
  margin-top: 30px;
}

.speakers__list__speaker {
  width: 100%;
}

.speakers__container__title {
  font-weight: 500;
  font-size: 32px;
  text-align: center;
}

.speaker__name {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.speakers__list__speaker__image {
  width: calculateSpeakerSize($speakers-margin-in-vw, 4);
  height: calculateSpeakerSize($speakers-margin-in-vw, 4);
}

.speakers__list__speaker__image>img {
  width: calculateSpeakerImageSize($speakers-margin-in-vw, 4);
  height: auto;
}

.speakers__list__speakers__image {
  width: calculateSpeakerSize($speakers-margin-in-vw, 4) / 2;
}

.speakers__list__speakers__image>img {
  width: calculateSpeakerImageSizeDouble($speakers-margin-in-vw, 4);
  height: auto;
}

.speakers__list__speakers__image--right-border {
  border-right: 1px solid white;
}

@media (max-width: 800px) {
  .speakers__list {
    flex-wrap: wrap;
  }

  .speakers__container {
    margin: 64px $speakers-margin-in-px 0 $speakers-margin-in-px;
  }

  .speakers__container__title {
    font-size: 28px;
    font-weight: 500;
    text-align: left;
  }

  .speakers__list__speaker {
    width: auto;
  }

  .speakers__list__speaker {
    width: calculateSpeakerSize($margin-between-speakers, 2);
  }

  .speakers__list__speaker__image {
    width: calculateSpeakerSize($margin-between-speakers, 2);
    height: calculateSpeakerSize($margin-between-speakers, 2);
  }

  .speakers__list__speaker__image>img {
    width: calculateSpeakerImageSize($margin-between-speakers, 2);
    height: auto;
  }

  .speakers__list__speakers__image {
    width: calculateSpeakerSize($margin-between-speakers, 2) / 2;
  }

  .speakers__list__speakers__image>img {
    width: calculateSpeakerImageSizeDouble($margin-between-speakers, 2);
    height: auto;
  }

  .speaker__name {
    font-weight: 500;
  }

  .speakers__list__speaker:nth-child(4n+4),
  .speakers__list__speaker:nth-child(4n+3) {
    margin-top: 40px;
  }

  .speaker__company {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
