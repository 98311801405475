<template>
  <div>
    <span class="font-weight-bold">Follow us:</span>
    <a
        class="d-block white--text text-decoration-underline mt-2"
        href="https://www.instagram.com/devfestukraine/"
        target="_blank">
      Instagram
    </a>
    <a
        class="d-block white--text text-decoration-underline mt-2"
        href="https://www.facebook.com/events/614443302906753/"
        target="_blank">
      Facebook
    </a>
    <a
        class="d-block white--text text-decoration-underline mt-2"
        href="https://twitter.com/devfest_ukraine/"
        target="_blank">
      Twitter
    </a>
  </div>
</template>

<script>
export default {
  name: 'FollowBlock'
}
</script>

<style scoped>

</style>